import { createSlice } from '@reduxjs/toolkit'
import { getSessionId } from '../../utils/DataDog'

export type UserState = {
    fullName: string | null
    firstName: string | null
    lastName: string | null
    email: string | null
    userId: string | null
    companyId: string | null
    analyticsId: string | null
    isLegacy: boolean
    ddSessionId: string
    userProfileImageUrl: string | null
    thriveTosAcceptedServerTime: string | null
    userRegion: string | null
    userSignupCode: string | null
    thriveRoles: string[]
}

const initialState = {
    fullName: null,
    firstName: null,
    lastName: null,
    email: null,
    userId: null,
    companyId: null,
    analyticsId: null,
    isLegacy: false,
    ddSessionId: getSessionId?.(), // This is loaded from root and can be unreliable
    userProfileImageUrl: null,
    thriveTosAcceptedServerTime: null,
    userRegion: null,
    userSignupCode: null,
    thriveRoles: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userUpdateFullName: (state, { payload }) => {
            state.fullName = payload
        },
        userSetFirstName: (state, { payload }) => {
            state.firstName = payload
        },
        userSetLastName: (state, { payload }) => {
            state.lastName = payload
        },
        userUpdateEmail: (state, { payload }) => {
            state.email = payload
        },
        userUpdateUserId: (state, { payload }) => {
            state.userId = payload
        },
        userUpdateCompanyId: (state, { payload }) => {
            state.companyId = payload
        },
        userSetIsLegacy: (state, { payload }) => {
            state.isLegacy = payload
        },
        userSetAnalyticsId: (state, { payload }) => {
            state.analyticsId = payload
        },
        userSetDDSessionId: (state) => {
            state.ddSessionId = getSessionId?.() ?? null
        },
        userSetUserProfileImageUrl: (state, { payload }) => {
            state.userProfileImageUrl = payload
        },
        userSetThriveTosAcceptedServerTime: (state, { payload }) => {
            state.thriveTosAcceptedServerTime = payload
        },
        userSetRegion: (state, { payload }) => {
            state.userRegion = payload
        },
        userSetSignupCode: (state, { payload }) => {
            state.userSignupCode = payload
        },
        userSetThriveRoles: (state, { payload }) => {
            state.thriveRoles = payload
        }
    }
})

export const {
    userUpdateEmail,
    userUpdateFullName,
    userSetFirstName,
    userSetLastName,
    userUpdateUserId,
    userUpdateCompanyId,
    userSetIsLegacy,
    userSetAnalyticsId,
    userSetDDSessionId,
    userSetUserProfileImageUrl,
    userSetThriveTosAcceptedServerTime,
    userSetRegion,
    userSetSignupCode,
    userSetThriveRoles
} = userSlice.actions

export default userSlice.reducer
